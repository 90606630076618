import React, { useState, useEffect, useDebugValue } from "react";
import { api_base, loadUsers, updateUser } from "../services/consts";
import OurHeader from "./OurHeader";

function AssignPricelists(props) {
  const [pricelists, setPricelists] = useState([]);
  const [users, setUsers] = useState([]);

  const loadPricelists = () => {
    fetch(api_base + "/pricelists/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        setPricelists(data);
      });
  };

  const removePricelist = (pricelist_id) => {
    fetch(api_base + "/pricelists/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pricelist_id: pricelist_id }),
    })
      .then((res) => {
        if (res) {
          return res;
        }
        return null;
      })
      .then((data) => {
        loadPricelists(data);
      });
  };

  const assignPricelist = (pricelist_id, user) => {
    user.pricelist_id = pricelist_id;
    updateUser(user);
    window.setTimeout(() => {
      loadPricelists();
      loadUsers((data) => setUsers(data));
    }, 300);
  };

  useEffect(() => {
    loadPricelists();
    loadUsers((data) => setUsers(data));
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Pricelist</th>
              {/* {users.map((u) => {
                return <th scope="col">{u.username}</th>;
              })} */}
            </tr>
          </thead>
          <tbody>
            {users.length &&
              users.map((u) => {
                return (
                  <tr key={u._id}>
                    <td>{u.username}</td>
                    {/* <th>{u.date}</th> */}
                    <select
                      onChange={(evt) => {
                        assignPricelist(evt.target.value, u);
                      }}
                    >
                      <option>-</option>
                      {pricelists.length &&
                        pricelists.map((p) => {
                          return (
                            <option
                              selected={u.pricelist_id === p._id}
                              value={p._id}
                            >
                              {p.name} - {p.date}
                            </option>
                          );
                        })}{" "}
                    </select>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AssignPricelists;

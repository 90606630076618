import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import ManagePricelists from "./components/ManagePricelists";
import AssignPricelists from "./components/AssignPricelists";
import ManageUsers from "./components/ManageUsers";
import Stats from "./components/Stats";
import Search from "./components/Search";
import UserSettings from "./components/UserSettings";

const routing = (
  <Router>
    <div>
      <Route path="/stats" component={Stats} />
      <Route path="/search" component={Search} />
      <Route path="/manage-pricelists" component={ManagePricelists} />
      <Route path="/manage-users" component={ManageUsers} />
      <Route path="/assign-pricelists" component={AssignPricelists} />
      <Route path="/user-settings" component={UserSettings} />
      <Route exact path="/" component={Login} />
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers } from "../services/consts";
import OurHeader from "./OurHeader";
import GoogleMapReact from "google-map-react";

function Stats(props) {
  const [recentSearches, setRecentSearches] = useState([]);
  const [mostSearchedParts, setMostSearchedParts] = useState([]);
  const [userSearches, setUserSearches] = useState([]);
  const [maxSearches, setMaxSearches] = useState(0);
  const [mapMode, setMapMode] = useState("pin");
  const [searchCounts, setSearchCounts] = useState([]);
  const mapContainer = useRef({});

  const loadRecentSearches = () => {
    fetch(api_base + "/searches/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        const counts = {};
        data.forEach(function (item) {
          var dateS = item.date.split(" ")[0];
          var hour = new Date(item.date).getHours();

          if (typeof counts[dateS] == "undefined") {
            counts[dateS] = {};
          }

          if (typeof counts[dateS][hour] == "undefined") {
            counts[dateS][hour] = 0;
          }

          counts[dateS][hour] += 1;
        });

        setRecentSearches(data);
      });
  };

  const loadSearchCounts = () => {
    fetch(api_base + "/searches/group-by-location", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        setSearchCounts(data);
      });
  };

  const loadMostSearchUsers = () => {
    fetch(api_base + "/searches/group-by-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        if (data.length > 0) {
          setUserSearches(data);
        }
      });
  };

  const loadMostSearchedParts = () => {
    fetch(api_base + "/searches/group-by-part", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        if (data.length > 0) {
          setMaxSearches(data.length);

          setMostSearchedParts(data);
        }
      });
  };

  const defaultProps = {
    center: {
      lat: 37.0,
      lng: -84.63,
    },
    zoom: 6,
  };

  const AnyReactComponent = ({ text }) => (
    <div
      style={{
        width: "10px",
        height: "10px",
        backgroundColor: "red",
        borderRadius: "5px",
      }}
    ></div>
  );

  function MyMarker(props) {
    if (mapMode === "pin") {
      return (
        <div
          style={{
            border: "2px",
            borderRadius: "50%",
            borderWidth: "3px",
            padding: "5px",
            height: `${props.count}px`,
            width: `${props.count}px`,
            backgroundColor: "red",
            borderColor: "red",
            opacity: 0.4,
          }}
        ></div>
      );
    }
    return (
      <div style={{ border: "2px", borderRadius: "50%", padding: "5px" }}>
        {props.text}
      </div>
    );
  }

  useEffect(() => {
    loadMostSearchedParts();
    loadRecentSearches();
    loadMostSearchUsers();
    loadSearchCounts();
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>

      <div class="container" style={{ marginTop: "40px" }}>
        <div class="row">
          {/* <div class="col-md-12 text-center">
            <h2>Map of Recent Searches</h2>
            <p>Data on this dashboard is for the last 1,000 searches</p>
            <div style={{ height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA7lIOssygTlsjWhUe2z329azbSTqUXWXs",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {recentSearches.map((u) => {
                  if (u.coords) {
                    if (u.coords.latitude) {
                      return (
                        <MyMarker
                          lat={u.coords.latitude}
                          lng={u.coords.longitude}
                          text={u.oem}
                        ></MyMarker>
                      );
                    }
                  }
                })}
              </GoogleMapReact>
            </div>
          </div> */}

          <div class="col-md-12 text-center">
            <h2>Map of Recent Searches</h2>
            <p>Data on this dashboard is for the last 1,000 searches</p>
            <div style={{ height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA7lIOssygTlsjWhUe2z329azbSTqUXWXs",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {searchCounts.map((u) => {
                  if (u.coords) {
                    if (u.coords.latitude) {
                      return (
                        <MyMarker
                          lat={u.coords.latitude}
                          lng={u.coords.longitude}
                          count={u.count}
                          text={u.count.toString()}
                        ></MyMarker>
                      );
                    }
                  }
                })}
              </GoogleMapReact>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card mt-3">
              <div class="card-body">
                <h4>Search Volume by User</h4>
                {userSearches.map((u) => {
                  const width = (u.total / maxSearches) * 100;
                  return (
                    <div>
                      <p>
                        <strong>User: </strong>
                        {u._id}
                      </p>
                      <p>
                        <strong>Searches: </strong>
                        {u.total}
                      </p>
                      <div class="progress" style={{ height: "3px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: width + "%" }}
                          value={((u.total / maxSearches) * 100).toString()}
                          aria-valuenow={(
                            (u.total / maxSearches) *
                            100
                          ).toString()}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card mt-3">
              <div class="card-body">
                <h4>Recent Searches</h4>
                {recentSearches.map((u) => {
                  return (
                    <div>
                      <p>
                        <strong>Username: </strong> {u.username}
                      </p>
                      <p>
                        <strong>OEM:</strong> {u.oem}
                      </p>
                      <p>
                        <strong>Price:</strong> $
                        {parseFloat(u.price).toFixed(2)}
                      </p>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mt-3">
              <div class="card-body">
                <h4>Search Volume by Part</h4>
                {mostSearchedParts.map((u) => {
                  const width = (u.total / maxSearches) * 100;
                  return (
                    <div>
                      <div>
                        <p>
                          <strong>Part: </strong>
                          {u._id}
                        </p>
                        <p>
                          <strong>Searches: </strong>
                          {u.total}
                        </p>
                      </div>
                      <div class="progress" style={{ height: "3px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: width + "%" }}
                          value={((u.total / maxSearches) * 100).toString()}
                          aria-valuenow={(
                            (u.total / maxSearches) *
                            100
                          ).toString()}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Stats;
